@import "src/modules/common/styles/utils/variables";

.nameCard {
  border-radius: var(--card-radius);
  background-color: $accent-secondary;
  padding: 30px 20px 40px 30px;
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px;
    color: $black;
  }

  &__title {
    display: inline-flex;
    align-items: center;
    font-family: var(--default-font-family, "SuisseIntl");
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    color: $black;
    margin: 5px 0 0 0;
  }

  &__actions {
    position: absolute;
    top: 30px;
    right: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
  }

  & + & {
    margin-top: 40px;
  }

}

.legalNameIndicator {
  margin-bottom: 15px;

  label {
    margin: 0;
  }
}

.nameCardHistory {
  background-color: #E4EBF1;
}

.changedTimestamp {
  height: 18px;
  position: absolute;
  left: 30px;
  top: 17px;
  color: $legato-gray-dark;
  font-family: var(--default-font-family, "SuisseIntl");
  font-weight: 400;
}

.isniInfoIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #0061ab;
  margin: 0 0 1px 5px;
}

.isniInfoSpinner {
  margin: 0 0 1px 5px;
}

.isniInfoTooltip {
  opacity: 1 !important;
  padding: 0 !important;
  border: 1px solid #cbcbcb !important;
  max-width: 450px !important;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}

.isniInfoContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px 10px;

  &__column {
    padding: 20px;
  }
}

.isniInfoLabel {
  font-weight: bold;
  margin: 10px 0;
}

.isniInfoFooter {
  text-align: right;
  padding: 13px 0;
  background-color: $accent-secondary;
}

.isniInfoEmpty {
  color: $legato-gray;
  margin: 20px 60px;
}

.isniSpinnerWrapper {
  height: 30px;
  width: 110px;
  padding: 5px 10px;
}

.uaidLinkBtn {
  padding: 0;
  gap: 0;
  font-family: var(--default-font-family, "SuisseIntl");
  color: $legato-branded-primary;
  font-weight: 600;
  line-height: 18px;
}

.uaidLinkBtnIcon {
  margin: 0 0 1px 5px;
  font-size: 13px;
  line-height: 15px;
  padding: 0;
}

.isniDetail {
  font-weight: 600;
  color: $legato-branded-primary;
  line-height: 16px;
}

.isniAKA {
  color: $second-black;
}

.additionalTooltip {
  line-height: 18px;
  & label {
    color: $black;
  }
}

.remarks label {
  line-height: 18px;
  color: $black;
}

.competenciesParagraph {
  line-height: 18px;
  color: $second-black;
}

.isniButton {
  padding: 10px 15px !important;
}