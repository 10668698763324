@import 'src/modules/common/styles/utils/variables';

.headerBlock {
}

.identifier {
  position: relative;
  &:hover {
    .identifier__buttonWrapper,
    .identifier__button {
      visibility: visible;
    }
  }
  &__field {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    line-height: 18px;
  }

  &__value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    white-space: nowrap;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $second-black;
  }

  &__extraInfo {
    position: absolute;
    top: 1px;
    left: 27px;
  }

  &__buttonWrapper,
  &__button {
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    padding: 0;
    border: none;
    color: #0061ab;
    background: none;
    margin: auto;
  }
}

.label {
  display: flex;
  font-family: var(--default-font-family, "SuisseIntl");
  align-items: center;
  color: #383838;
  line-height: 18px;
  font-weight: 600;
  a {
    color: $legato-branded-primary;
  }
}

.noData {
  padding: 10px;
  color: #c1c4cc;
}

.copyIcon {
  color: $legato-branded-primary;
}