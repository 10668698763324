.exportBtn {
  align-self: flex-end;
  margin-top: 10px;
}
.mainInfo {
  min-height: 0;
}
.resultsTable {
  & th {
    position: sticky;
    top: var(--adv-search-results-table-top) !important;
  }
}

.emptyNameValueTable {
  & th {
    top: var(--adv-search-empty-name-results-table-top) !important;
  }
}

.subheader {
  max-height: unset;
  margin-bottom: 0;
  padding-bottom: 15px;
}