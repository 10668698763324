// MARGINS
.m {
  @for $i from 0 through 50 {
    &-#{$i} {
      margin: $i + px;
    }
  }
  @for $i from 0 through 50 {
    &t-#{$i} {
      margin-top: $i + px;
    }
  }
  @for $i from 0 through 50 {
    &r-#{$i} {
      margin-right: $i + px;
    }
  }
  @for $i from 0 through 50 {
    &b-#{$i} {
      margin-bottom: $i + px;
    }
  }
  @for $i from 0 through 50 {
    &l-#{$i} {
      margin-left: $i + px;
    }
  }
  @for $i from 0 through 50 {
    &y-#{$i} {
      margin-top: $i + px;
      margin-bottom: $i + px;
    }
  }
  @for $i from 0 through 50 {
    &x-#{$i} {
      margin-left: $i + px;
      margin-right: $i + px;
    }
  }
}

// PADDING
.p {
  @for $i from 0 through 50 {
    &-#{$i} {
      padding: $i + px;
    }
  }
  @for $i from 0 through 50 {
    &t-#{$i} {
      padding-top: $i + px;
    }
  }
  @for $i from 0 through 50 {
    &r-#{$i} {
      padding-right: $i + px;
    }
  }
  @for $i from 0 through 50 {
    &b-#{$i} {
      padding-bottom: $i + px;
    }
  }
  @for $i from 0 through 50 {
    &l-#{$i} {
      padding-left: $i + px;
    }
  }
  @for $i from 0 through 50 {
    &y-#{$i} {
      padding-top: $i + px;
      padding-bottom: $i + px;
    }
  }
  @for $i from 0 through 50 {
    &x-#{$i} {
      padding-left: $i + px;
      padding-right: $i + px;
    }
  }
}

.logo-margin-left {
  margin-left: $logo-space;
}

.columns-4 {
  display: grid;
  grid-template-columns: repeat( 4, minmax(200px, 1fr));
  gap: 20px;
}