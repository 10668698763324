@import '../utils/variables';

@media (min-width: 1500px) {
  .search-header {
    .col-sm-8 {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}

.source-tag {
  color: $lt-black-3;
}

.search-result td {
  position: relative;
}
.__react_component_tooltip.artist-results-tooltip {
  position: absolute;
}

.search-header {
  margin-bottom: $space-5;
  h1 {
    margin: $space-5 0;
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.identifiers-column {
  display: flex;
  justify-content: flex-start;
  .identifiers-labels {
    width: 100px
  }
  .identifiers-copy-button{
    color: #0032A0;
    cursor: pointer;
    margin-left: 1rem;
    display: none;
    padding: 0;
    border: none;
    background: none;
  }
  &:hover > .identifiers-copy-button{
    display: block;
  }
}

.results-box {
  border-top: none;
  padding-top: 0;
  flex: auto;
  .result-item {
    td {
      vertical-align: middle;
      padding: 0 15px !important;
      height: 45px;
    }
    &.details-open {
      background-color: #e3f1fc;
      box-shadow: inset 2px 0 0 0 #0061ab;
    }
  }
  .result-item-details {
    padding: 20px;
    background-color: #f2f9ff;
    &:hover {
      background-color: #f2f9ff !important;
      box-shadow: none !important;
    }
    td {
      border: 0;
      padding: 20px;
      font-size: 11px;
      .result-item-detail-labels {
        font-weight: bold;
        white-space: nowrap;
      }
      .result-item-detail-values{
        width: 320px;
      }
      .row-flex{
        display: flex;
        flex-direction: row;
        
      }
      .result-item-comment-section {
        p:first-child { font-weight: bold };
      }
      .result-item-detail-line {
        margin-bottom: 5px;
        margin-left: 10px;
        &.separator {
          margin-top: 14px;
        }
      }
      .result-item-comment-values{
        margin-bottom: 5px;
        margin-left: 10px;
        max-width: 150px;
      }
    }
  }

  .btn-toggle-result-details {
    border: 0;
    position: relative;
    line-height: 0;
    font-size: 0;
    i {
      font-size: 13px;
      line-height: 1;
    }
  }
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: #fff;
  z-index: 4;
  p {
    margin: 0;
    margin-left: $space-3;
  }
  .btn-group {
    margin-right: $space-3;
  }
}

.search-results__table-wrapper {
  position: relative;
  padding: 0 10px;
  word-break: break-word;
}

.search-results {
  padding: 0;
  .table {
    margin-bottom: 0;
    .name {
      max-width: 15ch;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .isni-col {
      min-width: 22ch;
    }

    .uaid-col {
      min-width: 8ch;
    }
    tbody {
      tr {
        &:hover {
          background-color: $accent-primary-lt;
          box-shadow: inset 2px 0 0 0 $accent-primary;
        }
      }
    }
  }
}

// PAGINATION
.pagination-nav {
  display: flex;
  justify-content: center;
  .pagination {
    padding-top: $space-10;
    margin-top: 0;
  }
}

// CREATE BUTTONS
.empty-result-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.btn-black {
  background-color: #3F3F3F;
  border: none;
  padding: 17px 25px;
  margin: 0 16px;

  &:hover, &:active {
    background-color: #3F3F3F !important;
  }
}
